/***************************************************
 * > Typography CSS
***************************************************/

@font-face {
    font-family: 'Brhendrix';
    src: local('Brhendrix'), url('../fonts/BRHendrix-Medium.woff2') format('woff2'), url('../fonts/BRHendrix-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brhendrix';
    src: local('Brhendrix'), url('../fonts/BRHendrix-Regular.woff2') format('woff2'), url('../fonts/BRHendrix-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brhendrix';
    src: local('Brhendrix'), url('../fonts/BRHendrix-Black.woff2') format('woff2'), url('../fonts/BRHendrix-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brhendrix';
    src: local('Brhendrix'), url('../fonts/BRHendrix-Bold.woff2') format('woff2'), url('../fonts/BRHendrix-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporativeslab';
    src: local('Corporativeslab'), url('../fonts/CorporativeSlab-Bold.woff2') format('woff2'), url('../fonts/CorporativeSlab-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporativeslab';
    src: local('Corporativeslab'), url('../fonts/CorporativeSlab-Medium.woff2') format('woff2'), url('../fonts/CorporativeSlab-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporativeslab';
    src: local('Corporativeslab'), url('../fonts/CorporativeSlab-Regular.woff2') format('woff2'), url('../fonts/CorporativeSlab-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


/* Icons */

@font-face {
    font-family: 'sftour';
    src: url('../fonts/sftour.eot?38262726'), url('../fonts/sftour.eot?38262726#iefix') format('embedded-opentype'), url('../fonts/sftour.woff2?38262726') format('woff2'), url('../fonts/sftour.woff?38262726') format('woff'), url('../fonts/sftour.ttf?38262726') format('truetype'), url('../fonts/sftour.svg?38262726#sftour') format('svg');
    font-weight: 400;
    font-style: normal;
}