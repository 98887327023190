/***************************************************
 * > Custom CSS
***************************************************/

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html.w-mod-touch * {
    background-attachment: scroll !important;
}

.w-embed:before,
.w-embed:after {
    display: none;
}

body {
    background-color: #fffcd5;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 252, 214, 0.75)), to(rgba(255, 252, 214, 0.75))), url('../images/texturetastic_gray_2X.jpg');
    background-image: linear-gradient(180deg, rgba(255, 252, 214, 0.75), rgba(255, 252, 214, 0.75)), url('../images/texturetastic_gray_2X.jpg');
    background-position: 0px 0px, 0px 0px;
    background-size: auto, 476px;
    font-family: Corporativeslab, sans-serif;
    color: #2c3c45;
    font-size: 1vw;
    line-height: 1.6;
    font-weight: 400;
}

h1 {
    margin-top: 0px;
    margin-bottom: 0.5em;
    font-family: Corporativeslab, sans-serif;
    font-size: 3.2em;
    line-height: 1;
    font-weight: 700;
}

h2 {
    margin-top: 0px;
    margin-bottom: 0.75em;
    font-family: Brhendrix, sans-serif;
    color: #403933;
    font-size: 2em;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0em;
}

h3 {
    margin-top: 0.4em;
    margin-bottom: 0.2em;
    font-family: Brhendrix, sans-serif;
    font-size: 1.5em;
    line-height: 1.3;
    font-weight: 700;
}

h4 {
    margin-top: 0.4em;
    margin-bottom: 1em;
    font-family: Corporativeslab, sans-serif;
    font-size: 1.2em;
    line-height: 1.4;
    font-weight: 700;
}

h5 {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    font-family: Corporativeslab, sans-serif;
    font-size: 1em;
    line-height: 1.4;
    font-weight: 700;
}

h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.4;
}

p {
    margin-bottom: 2em;
    font-size: 1.2em;
}

a {
    -webkit-transition: border-color 300ms ease-out, background-color 300ms ease-out, color 300ms ease-out;
    transition: border-color 300ms ease-out, background-color 300ms ease-out, color 300ms ease-out;
    font-weight: 700;
    text-decoration: none;
    /*white-space: break-spaces;*/
}

ul {
    margin-top: 0px;
    margin-bottom: 30px;
    padding-left: 20px;
}

ol {
    margin-top: 0px;
    margin-bottom: 10px;
    padding-left: 20px;
}

li {
    font-size: 1.2em;
}


/* Stop Webflow from setting color */

.w-nav-link,
.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
    color: unset;
}

a,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
}

figure {
    margin: 0
}

img {
    display: inline-block;
    max-width: 100%;
}

svg {
    display: block;
}

label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: 700;
}

strong {
    font-weight: bold;
}

.cite {
    font-family: Brhendrix, sans-serif;
    color: #52baba;
    font-size: 0.8em;
    font-weight: 700;
    text-align: left;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-style: normal;
    display: block;
}

.button,
.btn {
    margin-bottom: 0.5em;
    padding: 1.4em 2.5em;
    border-style: solid;
    border-width: 2px;
    border-color: #f2ca61;
    border-radius: 5em;
    background-color: #f2ca61;
    -webkit-transition: color 200ms ease-out, background-color 400ms ease;
    transition: color 200ms ease-out, background-color 400ms ease;
    font-family: Corporativeslab, sans-serif;
    color: #2c3c45;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.06em;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    line-height: inherit;
    cursor: pointer;
}
.button-row {
	margin-left: .5em;
	margin-right: .5em;
}

.button:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
    background-image: none;
    color: #faf7ed;
}

.btn.add {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0em;
    margin-left: 0.8em;
    padding: 0em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    font-size: 1.2em;
}

.btn.remove {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.8em;
    margin-left: 0.2em;
    padding: 0em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    font-size: 1.2em;
}

.button.is-style-outline {
    position: relative;
    border-style: solid;
    border-width: 2px;
    border-color: #f2ca61;
    background-color: transparent;
    color: #2c3c45;
}

.button.is-style-outline:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
    color: #faf7ed;
}

.button.is-style-outline.btn-footer {
    margin-bottom: 2.8em;
    border-color: #faf7ed;
    color: #faf7ed;
    font-size: 0.85em;
}

.button.is-style-outline.btn-footer:hover {
    border-color: #f2ca61;
    background-color: #f2ca61;
    color: #2c3c45;
}

.button.is-style-inverse {
    border-color: #faf7ed;
    background-color: #faf7ed;
}

.button.is-style-inverse:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
}

.button.is-style-inverse.btn-footer {
    border-color: #f2ca61;
    background-color: #f2ca61;
    color: #2c3c45;
}

.button.is-style-inverse.btn-footer:hover {
    background-color: #f2ca61;
}

.button.h-tours {
    position: static;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100%;
    max-width: 82%;
    margin-right: auto;
    margin-bottom: 1.8em;
    margin-left: auto;
    padding: 0.9em 1em;
    border-style: solid;
    border-width: 2px;
    border-color: #f2ca61;
    background-color: #f2ca61;
    background-image: none;
    color: #2c3c45;
    font-size: 0.9em;
    cursor: pointer;
}

.button.h-tours:hover {
    border-color: #f2ca61;
    background-color: transparent;
    color: #f2ca61;
}

.button.h-tours::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.button.bt-availability {
    margin-right: 3em;
    margin-left: 3em;
    padding-right: 3.2em;
    padding-left: 3.2em;
    border-style: solid;
    border-width: 2px;
    border-color: #f2ca61;
    color: #000;
    font-size: 0.8em;
}

.button.bt-availability:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
}

.button.aside {
    display: block;
    margin-top: 0.75em;
    margin-bottom: 0em;
    font-size: .85em;
    padding: 1em 1.5em;
}

.button.submit {
    margin-bottom: 0px;
}

.button.h-hero {
    height: auto;
    margin-top: 15px;
    border-top-style: none;
    border-bottom-style: none;
    background-image: none;
    font-size: 1.1em;
}

.button.tour-more {
    margin-bottom: 0em;
}

.button.bt-request {
    position: relative;
    padding-right: 3.2em;
    padding-left: 3.2em;
    border-style: solid;
    border-width: 2px;
    border-color: #f2ca61;
    background-color: transparent;
    color: #f2ca61;
    font-size: 0.8em;
}

.button.bt-request:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
    color: #faf7ed;
}

.button.btn-directions {
    margin-left: 2.6em;
}

.button.btn-directions:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
}

.button.btn-tripadvisor {
    display: inline-block;
    padding-right: 2.3em;
    padding-left: 2.3em;
    font-size: 0.8em;
    line-height: 1.3;
    text-align: center;
}
.entry-content p .button {
    font-size: 0.75em;
}


/* --------------------------------------
* >	Accordion  	
-----------------------------------------*/

.accordion-panel {
    z-index: 0;
    width: 100%;
    margin-bottom: 0.7em;
    padding: 0em;
    border-radius: 0px;
    background-color: rgba(242, 202, 97, 0.6);
}

.accordion-panel:hover {
    background-color: #f2ca61;
}

.accordion-panel.tiered-pricing-panel {
    margin-bottom: 2.3em;
    background-color: transparent;
}

.accordion-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1.2em 1.4em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: normal;
}

.accordion-toggle.tiered-pricing-toggle {
    background-color: transparent;
}

.accordion-toggle:before {
    content: "+";
    display: inline-block;
    padding-right: .65em;
    font-size: 2em;
    width: 15px;
    color: #444447;
    float: left;
}

.accordion-toggle.w--open:before {
    content: "–";
    font-weight: 400;
}

.accordion-panel:first-of-type {
    border-top: 0;
}

.accordion-toggle.tiered-pricing-toggle:before,
.accordion-toggle.tiered-pricing-toggle.w--open:before {
    content: '';
    padding-right: 0;
    padding-left: 0;
    width: auto;
    display: none;
}

.accordion-header {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Brhendrix, sans-serif;
    font-size: 1.3em;
    line-height: 1.4;
    font-weight: 700;
    padding-right: .5em;
}

.accordion-header.tiered-pricing-header {
    font-family: Corporativeslab, sans-serif;
    font-size: 1.2em;
    font-weight: 700;
}

.accordion-content {
    width: 100%;
}

.accordion-content.w--open {
    position: static;
    padding-top: 0em;
    padding-right: 3.4em;
    padding-left: 3.8em;
    background-color: transparent;
    padding-bottom: 2.3em;
}

.accordion-content.tiered-pricing-content {
    padding-top: 0.7em;
    padding-right: 0em;
    padding-left: 0em;
}


/* --------------------------------------
* >	Forms  	
-----------------------------------------*/

select,
.w-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}

.input-checkbox {
    -webkit-appearance: none;
}

input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
}

input:not([type=submit]):not([type=file]),
select,
textarea,
.w-input,
.w-select {
    -webkit-appearance: none;
    margin-bottom: 0.75em;
    padding: 0.5em;
    border: 2px solid rgba(51, 51, 51, 0.3) !important;
    background-color: transparent !important;
    -webkit-transition: background-color 300ms ease-out, border-color 300ms ease-out;
    transition: background-color 300ms ease-out, border-color 300ms ease-out;
}

input:not([type=submit]):not([type=file]),
select {
    height: 4em !important;
}

textarea {
    min-height: 16em;
}

#book-now-form br {
    display: none;
}

.gform_body {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.ginput_container.ginput_complex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.gform_fields {
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.name_first {
    display: inline-block;
    width: 50%;
    margin-right: 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.name_last {
    display: inline-block;
    width: 50%;
    padding-right: 0px;
}

.address_line_1 {
    width: 100%;
}

.address_line_2 {
    width: 100%;
}

.address_city {
    margin-right: 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.address_state {
    margin-right: 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.checkbox,
input[type=checkbox] {
    width: 25px;
    height: 25px;
    margin: 0px;
    padding: 0.2em;
    border-width: 2px;
    border-color: rgba(51, 51, 51, 0.3);
    background-color: #fff;
    border-style: solid;
    position: relative;
}

.checkbox:hover,
input[type=checkbox]:hover {
    border-color: #009df7;
}

.checkbox.w--redirected-focus,
input[type=checkbox].w--redirected-focus {
    border-color: #009df7;
    box-shadow: none;
}

input[type=checkbox]:checked {
    background: #1c3b4f;
    position: relative;
}

.checkbox.w--redirected-checked,
input[type=checkbox].w--redirected-checked {
    background-color: #009df7;
    position: relative;
}

input[type=checkbox]:checked:before,
input[type=checkbox].w--redirected-checked:before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 4px;
    top: -4px;
    font-size: 1.3em;
}

.label {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-left: 0.7em;
    background-color: transparent;
    font-size: 0.8em;
}

.radio-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.radiobutton,
input[type="radio"] {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 0px;
    padding: 0em;
    border-width: 2px;
    border-color: rgba(51, 51, 51, 0.3);
    background-color: #fff;
    border-style: solid;
    border-radius: 100%;
}

.radiobutton.w--redirected-checked,
input[type="radio"].w--redirected-checked {
    border-color: #009df7;
    background-color: #fff;
    box-shadow: inset 0 0 0 3px #009df7;
}

input[type=radio]:checked {
    border-color: #1c3b4f;
    -webkit-box-shadow: inset 0 0 0 5px #1c3b4f;
    box-shadow: inset 0 0 0 5px #1c3b4f;
}

.radiobutton.label {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0.7em;
    background-color: transparent;
    font-size: 0.8em;
    line-height: 1.5;
    text-transform: none;
}

.freeform-row .freeform-column .freeform-instructions {
    margin: 0 0 .85em !important;
    font-size: 1rem !important;
    color: #2b3c45 !important;
}
.freeform-row .freeform-column .freeform-input[type=checkbox], .freeform-row .freeform-column .freeform-input[type=radio] {
    width: 25px !important;
    height: 25px !important;
}
.freeform-row .freeform-column .input-group-one-line label {
    display: flex !important;
}
.freeform-row .freeform-column label {
	font-size: 1rem !important;
}

.alignnone {
    display: block;
    margin-top: 0;
    margin-bottom: 20px;
    float: none;
}
.border-img {
    margin-bottom: 3em;
}
.border-img img {
    border-style: solid;
    border-width: .7vw;
    border-color: #fffffa;
}

.entry-content > div:first-child > figure {
    margin-top: -20px;
}

.block-spacer {
    height: 30px;
}

.site-header {
    position: relative;
    width: 100%;
    height: 7.1em;
    background-color: rgba(255, 252, 213, 0.21);
    box-shadow: 0 0 18px -10px rgba(0, 0, 0, 0.22);
    color: #2c3c45;
}

.wrap {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 2.6em;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('../images/texture-bg-1.svg');
    background-position: 50% 0%;
    background-size: 30em;
    background-repeat: repeat-x;
}
.site-header .wrap:before,
.site-header .wrap:after {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.burger-1 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.b1-bar1 {
    width: 30px;
    height: 4px;
    background-color: #faf7ed;
}

.b1-bar2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #faf7ed;
}

.b1-middle {
    width: 4px;
    height: 0px;
    background-color: #faf7ed;
}

.b1-bar3 {
    width: 30px;
    height: 4px;
    background-color: #faf7ed;
}

.title-area {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    z-index: 100;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 17em;
    padding: 0em;
    float: left;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: #2c453c;
    color: #fff;
}

.title-area a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 1.7em;
    padding-left: 1.4em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-image: none;
    text-align: center;
}
.oto-logo {
    position: relative;
    width: 100%;
    height: auto;
    color: #fff;
}

.menu-toggle {
    position: relative;
    display: none;
    padding: 0px;
}

.nav-primary {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #fffef0;
}

.menu-primary {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 65%;
    margin-left: 7em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 65em;
    -ms-flex: 0 65em;
    flex: 0 65em;
    background-color: transparent;
    list-style-type: none;
}

.menu-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0.8em 0.2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    background-image: none;
    -webkit-transition: color 200ms ease-out;
    transition: color 200ms ease-out;
    font-family: Corporativeslab, sans-serif;
    font-size: 1.25em;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.01em;
    list-style-type: none;
}

.menu-item:hover {
    background-image: none;
}

.menu-item.w--current:not(.button) {
    color: #5acccc;
}

.menu-item.drop-down {
    padding: 0px;
}

.menu-item.mega-drop-down {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

.menu-item.button {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    margin-bottom: 0em;
    padding: 0.6em 2em;
    border-radius: 0em;
    font-family: Corporativeslab, sans-serif;
    font-size: 1em;
    font-weight: 700;
}

.drop-down-toggle {
    position: relative;
}

.submenu-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0.8em 0.3em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

.sub-menu {
    position: relative;
    top: 100%;
    background-color: #fffef0;
}

.sub-menu.w--open {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0.5em 1em;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: #fffef0;
}

.sub-menu.sub-mega-menu {
    left: 50%;
    width: 85%;
    max-width: 100%;
    min-width: 0px;
    background-color: #fffef0;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
}
.sub-menu.sub-mega-menu.w--open {
    padding: 0.5em 2em;
}

.dropdown-links {
    padding-top: 0px;
    padding-right: 20px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-right: 1px solid rgba(149, 159, 30, 0.7);
}

.dropdown-links.customize {
    margin-top: 3em;
}

.dropdown-links.no-border {
    border-right-style: none;
}

.dropdown-menu-links {
    padding-bottom: 32px;
    border-left: 1px solid rgba(149, 159, 30, 0.7);
    padding-left: 2em;
    padding-right: 2em;
    align-self: stretch;
    /*display: -ms-grid;
    display: grid;
    flex-wrap: wrap;
    align-self: stretch;
    flex: 1;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;*/
}
.dropdown-menu-links:first-of-type {
	border:0;
}
.dropdown-menu-links:first-of-type .dropdown-menu-items {
    display: -ms-grid;
    display: grid;
    flex-wrap: wrap;
    align-self: stretch;
    flex: 1;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}

.dropdown-menu-link {
    display: block;
    padding: 1.25% 0.5em .5em;
    background-image: none;
    -webkit-transition: color 200ms ease-out;
    transition: color 200ms ease-out;
    color: #4c4c4a;
    line-height: 1.5;
    font-size: .9em;
}
.sub-menu.sub-mega-menu .dropdown-menu-link  {
    /*font-size: 0.75em;
    padding-bottom:0;*/
}

.dropdown-menu-link:hover {
    background-image: none;
    color: #60dbdb;
}

.dropdown-menu-links.customize {
    width: 100%;
    text-align: center;
    border-left: 0;
    margin-top: 2em;
    padding-bottom: .75em;
}

.dropdown-menu-items {
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}

.dropdown-menu-items.two-col-section {
    -webkit-column-count: 2;
    column-count: 2;
}

.dropdown-menu-heading {
    margin-bottom: 12px;
    padding-right: .5em;
    padding-left: .5em;
    background-image: none;
    font-family: Brhendrix, sans-serif;
    color: #577840;
    font-size: 0.8em;
    line-height: 1.4;
    font-weight: 700;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    max-width: 100%;
    display: inline-block;
}

.dropdown-menu-heading:hover {
    background-image: none;
}

.dropdown-menu-heading.button {
    padding: 1em 2em;
    color: #2c453c;
    text-align: left;
}

.dropdown-menu-heading.button:hover {
    background-color: #575757;
}

.dropdown-menu-heading.button.is-style-outline {
    border-color: #2c3c45;
    font-family: Corporativeslab, sans-serif;
    color: #2c3c45;
    font-size: 0.7em;
    text-align: center;
}

.dropdown-menu-heading.button.is-style-outline:hover {
    border-color: #60dbdb;
    background-color: transparent;
    color: #60dbdb;
}

.dropdown-menu--wrap {
    z-index: 2001;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    padding-top: .5em;
    padding-bottom: 1em;
}
.sub-mega-menu .dropdown-menu--wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex: 0 auto;	
    padding-top: 2em;
    flex-wrap: wrap;
}

.dropdown-menu-item {
    font-size: unset;
    list-style-type: none;
}

/*@media only screen and (min-width: 992px) and (max-width: 1279px) {
.dropdown-menu-link {
	font-size: 14px;
}	
}*/


/* --------------------------------------
* > Stop font scaling  	
-----------------------------------------*/

@media only screen and (min-width: 992px) and (max-width: 1279px) {
    .site-header {
        font-size: 13px;
    }
    .title-area {
	    font-size: 11px;
    }
    .menu-primary {
	    margin-left: 4em;
    }
}

@media only screen and (min-width: 1920px) {
    .site-header,
    .site-footer {
        font-size: 18px;
    }
}

.site-header .wrap:after {
    clear: both;
}

@media only screen and (min-width: 992px) {
    .site-header .wrap {
        height: calc(100% - 2.6em)
    }
}


.site-footer {
    position: relative;
    padding: 0px;
    background-color: #2c453c;
    color: #faf7ed;
}

.footer-top-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 93vw;
    max-width: 120em;
    margin-right: auto;
    margin-left: auto;
    padding: 3vw 4em;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.footer-area {
    position: relative;
    padding-right: 0em;
    padding-left: 0em;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.footer-area h3 {
    position: relative;
}

.footer-area h4 {
    position: relative;
    overflow: hidden;
    width: 0px;
    height: 0px;
    margin-top: 0px;
    margin-bottom: 1em;
    color: rgba(250, 245, 232, 0.74);
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.footer-area.first {
    padding-right: 0px;
    padding-left: 0px;
    text-align: center;
}

.footer-area.middle {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    text-align: left;
}

.footer-area.last {
    margin-bottom: 2em;
    padding-top: 0em;
    padding-right: 0px;
    padding-left: 0px;
    text-align: right;
}

.footer-link {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
    text-decoration: none;
}

.footer-link:hover {
    background-image: none;
}

.menu-footer {
    position: relative;
    margin-bottom: 3em;
    list-style-type: none;
}

.menu-footer a {
    position: relative;
    padding: 0.3em 0em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: Corporativeslab, sans-serif;
    font-size: 1.4em;
    text-align: left;
    letter-spacing: 0.03em;
    text-decoration: none;
}

.menu-footer a:hover {
    color: #f2ca61;
}

.social-icons-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.footer-social-icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0.25em;
    padding-left: 0.25em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    background-image: none;
    font-family: Sftour, sans-serif;
    text-decoration: none;
}

.footer-social-icons:hover {
    background-image: none;
    color: #f2ca61;
}

.footer-bottom-wrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 1em 4em 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.1em;
    text-align: center;
    letter-spacing: 0.03em;
    width: 93vw;
    max-width: 120em;
}

.footer-copy {
    display: inline-block;
    margin-bottom: 0px;
    padding-right: 5px;
    font-size: 0.8em;
}

.site-inner {
    position: relative;
}

.content-sidebar-wrap {
    position: relative;
}

.content {
    position: relative;
    overflow: visible;
}

.home .content {
    z-index: 1;
    background-image: url('../images/texture-bg-1.svg');
    background-position: 110% 30%;
    background-size: 40em;
    background-repeat: no-repeat;
}

.archive-tours .content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.content.page-about {
    background-image: url('../images/about-bt-right.svg'), url('../images/about-tp-left.svg');
    background-position: 100% 100%, 0% 0%;
    background-size: auto, auto;
    background-repeat: no-repeat, no-repeat;
}

.entry {
    position: relative;
}

.entry.about-page {
    background-image: url('../images/logo-watermark.svg'), linear-gradient(223deg, rgba(255, 252, 224, 0.65), hsla(0, 0%, 100%, 0)), url('../images/texture-bg1.svg');
    background-position: 95% 26vh, 0px 0px, 100% 0%;
    background-size: 27em, auto, 370px;
    background-repeat: no-repeat, repeat, no-repeat;
}

.entry-header {
    position: relative;
    padding: 60px 30px 40px;
    text-align: center;
}

.entry-title {
    position: relative;
    z-index: 1;
    margin: 0px auto;
    font-size: 2.8em;
    max-width: 25em;
}
.entry-content {
    position: relative;
    max-width: 60em;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 60px 80px;
}

.entry-content li {
    margin-bottom: 1em;
}

.entry-content a:not(.button),
.home .content p a:not(.button),
.underline {
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), color-stop(65%, rgba(215, 246, 246, 0)), color-stop(0, rgba(97, 219, 219, 0.4)), to(rgba(97, 219, 219, 0.4)));
    background-image: linear-gradient( 180deg, hsla(0, 0%, 100%, 0), rgba(215, 246, 246, 0) 65%, rgba(97, 219, 219, 0.4) 0, rgba(97, 219, 219, 0.4));
    font-weight: 700;
    text-decoration: none;
    white-space: break-spaces;
}

.entry-content a:not(.button):hover,
.home .content p a:not(.button):hover,
.underline:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), color-stop(65%, rgba(215, 246, 246, 0)), color-stop(0, #f2ca61), to(#f2ca61));
    background-image: linear-gradient( 180deg, hsla(0, 0%, 100%, 0), rgba(215, 246, 246, 0) 65%, #f2ca61 0, #f2ca61);
}

.dropdown-links:last-of-type {
    border-right: 0;
}

.entry-content a {
    word-wrap: break-word;
}

.entry-footer {
    position: relative;
}

.h-hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 4px none #60dbdb;
    border-bottom: 9px solid #f2ca61;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(7, 48, 69, 0.5)), to(rgba(7, 48, 69, 0.5)));
    background-image: linear-gradient(180deg, rgba(7, 48, 69, 0.5), rgba(7, 48, 69, 0.5));
}

@media only screen and (min-width: 600px) {
    .h-hero {
        height: calc(100vh - 7.1em);
    }
}

.h-hero h1 {
    width: 100%;
    color: #faf7ed;
    font-size: 3.2em;
    line-height: 1.3;
    text-align: center;
}

.h-hero p {
    display: none;
    font-size: 1.5em;
    text-align: center;
}

.h-company {
    position: relative;
    border-top: 9px none #f2ca61;
    background-image: url('../images/texture-bg-1.svg');
    background-position: 0% 0%;
    background-size: 35em;
    background-repeat: no-repeat;
}

.h-company h2 {
    color: #2c453c;
    font-size: 2.5em;
    font-weight: 700;
}

.h-company p {
    font-size: 1.2em;
    line-height: 1.7;
}

.h-tours {
    position: relative;
    text-align: center;
}

.h-tours h2 {
    display: inline-block;
    margin-bottom: 0.8em;
    padding: 0.1em 1.2em;
    border-radius: 4px;
    background-color: #f2ca61;
    -webkit-transform: skew(-20deg, 0deg);
    -ms-transform: skew(-20deg, 0deg);
    transform: skew(-20deg, 0deg);
    font-family: Corporativeslab, sans-serif;
    color: #2c3c45;
    font-size: 2.5em;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.02em;
}

.h-tours p {
    width: 82%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.h-tours h3 {
    width: 82%;
    margin-top: 2vw;
    margin-right: auto;
    margin-left: auto;
    /*padding-right: 2em;*/
    color: #2c3c45;
    font-size: 1.8em;
}

.h-gallery {
    position: relative;
    overflow: hidden;
}

.inner {
    width: 93vw;
    max-width: 120em;
    margin-right: auto;
    margin-left: auto;
    padding: 2vw 3em;
    background-image: none;
}

.inner.h-company--inner {
    z-index: 1;
    display: flex;
    padding-top: 10vw;
    padding-bottom: 10vw;
    justify-content: center;
    border-top-style: none;
}

.inner.h-tours--inner {
    z-index: 1;
    width: 100vw;
    max-width: 100%;
    padding: 4vw 0em 8vw;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(250, 247, 237, 0)), color-stop(30%, rgba(177, 186, 167, 0)), color-stop(0, #437843), color-stop(90%, #315242));
    background-image: linear-gradient(180deg, rgba(250, 247, 237, 0), rgba(177, 186, 167, 0) 30%, #437843 0, #315242 90%);
}

.inner.h-testimonials--inner {
    max-width: 80em;
    padding-top: 9vw;
    padding-bottom: 20vw;
    background-image: none;
    text-align: center;
}

.inner.gallery-list {
    width: 101%;
    max-width: 101%;
    margin-right: 0px;
    margin-left: -0.5%;
    padding: 0em 0% 0%;
}

.inner.h-why-what--inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10vw;
    padding-bottom: 10vw;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: transparent;
    background-image: none;
}

.inner.h-contact--inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0vw;
    padding-bottom: 4vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(207, 202, 81, 0.19);
    border-top-style: none;
    font-size: 1.2em;
    text-align: center;
}

.inner.h-hero--inner {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 75em;
    padding-right: 5.5em;
    padding-left: 5.5em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    border-top-style: none;
    border-bottom-style: none;
    background-image: none;
}

.inner.h-associates--inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0vw;
    padding-bottom: 6vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: transparent;
}

.h-company--entry {
    padding-right: 5em;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    align-self: center;
}

.h-company--img-block {
    display: block;
    flex: 1;
}

.h-company--img {
    display: block;
    width: 40vw;
    height: 34em;
    border-radius: 0px;
    box-shadow: 16px 16px 0 0 #2c3c45;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 100% 50%;
    object-position: 100% 50%;
}

.h-tours--item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 270px;
    -ms-flex: 1 0 270px;
    flex: 1 0 270px;
    background-color: #fffef0;
    box-shadow: 0 0 1.2em -1.1em #403933;
    -webkit-transition: box-shadow 300ms ease-out;
    transition: box-shadow 300ms ease-out;
    text-align: left;
    cursor: pointer;
}

.h-tours--item:hover {
    box-shadow: 0 0 1.2em -0.9em #000;
}

.h-testimonials {
    position: relative;
    background-image: url('../images/pacaya-bg3.jpg');
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #2c453c;
}

.h-testimonials h2 {
    margin-bottom: 1.6em;
    color: #597843;
    font-size: 1.6em;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.h-testimonials p {
    font-size: 1.6em;
    line-height: 1.6;
    text-align: center;
}

.h-why-what {
    position: relative;
    background-color: rgba(255, 189, 128, 0.25);
    background-image: url('../images/texture-bg-1.svg'), url('../images/texture-bg-1.svg');
    background-position: 100% 100%, 0px 0px;
    background-size: 35em, 35em;
    background-repeat: no-repeat, no-repeat;
}

.h-why-what h2 {
    max-width: 80%;
    color: #597843;
    font-size: 2.3em;
    font-weight: 700;
}

.h-why-what h3 {
    margin-bottom: 25px;
}

.h-why-what--inner {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 25em;
    -ms-flex: 1 0 25em;
    flex: 1 0 25em;
}

.h-contact {
    position: relative;
    border-top: 8px none #f2ca61;
    background-color: #2c453c;
    color: #faf7ed;
}

.h-contact h2 {
    margin-bottom: 1em;
    font-size: 2.2em;
    font-weight: 700;
    letter-spacing: 0.03em;
}

.h-contact p {
    margin-top: 0.8em;
    margin-bottom: 1em;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    font-size: 1.3em;
    text-align: center;
}

.why-what-list {
    padding-left: 0em;
    list-style-type: none;
}

.why-what-list li {
    margin-bottom: 13px;
    padding-left: 2em;
    background-image: url('../images/icon-checkmark.svg');
    background-position: 0% 0%;
    background-size: 1.4em;
    background-repeat: no-repeat;
}

.drop-down-toggle {
    background-image: none;
    text-decoration: none;
}

.drop-down-toggle:hover {
    background-image: none;
}

.archive-description {
    position: relative;
    display: block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 1em;
    margin-left: auto;
    padding: 3.5em 3em 1em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(254, 252, 223, 0.5)), to(rgba(254, 252, 223, 0.5))), url('../images/texture-bg1.svg'), url('../images/texture-bg1.svg');
    background-image: linear-gradient(180deg, rgba(254, 252, 223, 0.5), rgba(254, 252, 223, 0.5)), url('../images/texture-bg1.svg'), url('../images/texture-bg1.svg');
    background-position: 0px 0px, 100% 0%, 0px 0px;
    background-size: auto, 35em, 35em;
    background-repeat: repeat, no-repeat, no-repeat;
    text-align: center;
}

.archive-description.archive-tours-description {
    z-index: 1;
    display: inline-block;
    width: auto;
    max-width: 70%;
    margin: 4em auto -1.5em;
    padding: 0em;
    border-radius: 5px;
    background-color: #60dbdb;
    background-image: url('../images/texture-bg1.svg'), url('../images/texture-bg1.svg');
    background-position: 100% 0%, 0px 0px;
    background-size: 35em, 35em;
    background-repeat: no-repeat, no-repeat;
    -webkit-transform: skew(-20deg, 0deg);
    -ms-transform: skew(-20deg, 0deg);
    transform: skew(-20deg, 0deg);
}

.archive-title {
    width: 100%;
    max-width: 25em;
    margin: 0px auto .5em;
    line-height: 1.2;
    font-size: 2.8em;
}

.archive-title.archive-tours-title {
    display: inline-block;
    width: auto;
    margin-bottom: 0em;
    padding: 0.2em 2.1em;
    -webkit-transform: skew(20deg, 0deg);
    -ms-transform: skew(20deg, 0deg);
    transform: skew(20deg, 0deg);
}
.archive-title.archive-tours-title:before {
    content: "Choose Your ";
}

.icon-angle-down {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 0.65em;
    max-height: 13px;
    max-width: 13px;
    margin-right: 0px;
    margin-left: 7px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    color: #959f1e;
    font-size: 1.25em;
    text-align: center;
}

.footer-text {
    margin-bottom: 0em;
}

/* Flex Content Gallery */
.image-gallery {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.5vw;
  grid-row-gap: 1.5vw;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  list-style: none;
  padding:0;
}

.image-gallery .gallery-item {
  position: relative;
  list-style: none;
  margin: 0;
}
.image-gallery .gallery-item a,
.image-gallery .gallery-item a:hover {
	background-image: none;
}

@media screen and (max-width: 767px) {
  .image-gallery {
  	grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 479px) {
  .image-gallery {
  	grid-template-columns: 1fr 1fr;
  }
}

/* Home & Tour Galleries */
.gallery-list .gallery-item {
    padding: 0%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 360px;
    -ms-flex: 1 0 360px;
    flex: 1 0 360px;
}

.gallery-list .gallery-item.top-row {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
}

.gallery-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.gallery-item--link {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-filter 200ms ease-out;
    transition: -webkit-filter 200ms ease-out;
    transition: filter 200ms ease-out;
    transition: filter 200ms ease-out, -webkit-filter 200ms ease-out;
}

.gallery-item--link:hover {
    -webkit-filter: saturate(200%);
    filter: saturate(200%);
}

.gallery-list .gallery-item-img {
    width: 100%;
    height: 50vh;
    -o-object-fit: cover;
    object-fit: cover;
}
.tour-gallery--list .gallery-item-img {
    width: 100%;
    height: 35vh;
    max-height: 400px;
    -o-object-fit: cover;
    object-fit: cover;
}

.h-tours--img {
    display: block;
    width: 100%;
    height: 27em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
}

.tippy-top {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 2.6em;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-bottom: 1px none #e5e4d0;
    background-color: #38574b;
    color: #faf7ed;
}

.tippy-top-phone {
    background-image: none;
    font-size: 1em;
    text-decoration: none;
}

.tippy-top-phone:hover {
    background-image: none;
    color: #60dbdb;
}

.tippy-top-social-icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0em 0.55em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    background-image: none;
    font-family: Fontello;
    text-decoration: none;
}

.tippy-top-social-icons:hover {
    background-image: none;
    color: #f2ca61;
}

.tippy-top-icons-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-right: 2em;
    margin-left: 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-right: 1px solid #e5e4d0;
    border-left: 1px solid #e5e4d0;
}

.menu-tippy-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.menu-tippy-top a {
    padding: 0.3em 1.2em 0.1em;
    font-size: 0.8em;
    font-weight: 500;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.tippy-top-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    max-width: 120em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5em;
    padding-left: 1.5em;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer-area-link {
    background-image: none;
    text-decoration: none;
}

.footer-area-link:hover {
    background-image: none;
    color: #f2ca61;
}

.footer-area-link.phone-guatemala {
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 0.5em;
    padding-left: 2.3em;
    background-image: url('../images/icon-flag-guatemala.svg');
    background-position: 0% 50%;
    background-size: 1.5em;
    background-repeat: no-repeat;
    text-align: left;
}

.footer-area-link.phone-us {
    display: inline-block;
    margin-bottom: 1em;
    padding-left: 2.6em;
    background-image: url('../images/icon-flag-us.svg');
    background-position: 0% 50%;
    background-size: 1.5em;
    background-repeat: no-repeat;
    text-align: left;
}

.icon-mobile {
    font-weight: 500;
    letter-spacing: 0.03em;
}

.tour-description {
    position: relative;
    margin-bottom: 4em;
}

.tour-header {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 77vh;
    min-height: 600px;
    padding-right: 2em;
    padding-left: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 6px solid #60dbdb;
    background-color: #292929;
    opacity: 1;
}

@media only screen and (min-width: 768px) {
    .tour-header {
        height: calc(100vh - 7.1em);
    }
}

.tour-expectations {
    position: relative;
    margin-bottom: 4em;
}

.tour-title {
    position: relative;
    z-index: 10;
    width: 80%;
    max-width: 75em;
    margin-top: 0px;
    margin-bottom: 0.8em;
    color: #fff;
    font-size: 4.2em;
    line-height: 1.1;
    text-align: center;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
}

.tour-header--buttons {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5em;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.tour-featured-img {
    position: absolute;
    left: 0%;
    top: 0px;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.tour-details {
    position: relative;
    margin-bottom: 6em;
}

.tour-inner {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 95%;
    max-width: 95em;
    margin-right: auto;
    margin-left: auto;
    padding: 4em 0vw;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-template-areas: "sidebar content-area" "sidebar content-area" "sidebar ." "sidebar .";
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
}

.tour-aside {
    position: -webkit-sticky;
    position: sticky;
    top: 2em;
    padding: 2em;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 20em;
    -ms-flex: 0 20em;
    flex: 0 20em;
    border-radius: 1em;
    background-color: #fffef5;
    box-shadow: 0 1px 1.2em -1.1em #000;
}

.tour-content {
    position: relative;
    padding-left: 5.5em;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.tour-content h2 {
    margin-top: 0.7vh;
    margin-bottom: 1.2em;
    background-size: 354px;
    font-family: Corporativeslab, sans-serif;
    color: #2c3c45;
    font-size: 2.2em;
    font-weight: 700;
    text-align: left;
}

.tour-content h3 {
    color: #597843;
    margin-bottom: .5em;
}

.tour-detail--item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    border-bottom: 2px solid #e8e6cb;
}
.tour-detail--list {
    margin-bottom: 0;
}

.tour-detail--list li {
    font-size: 1em;
}

.tour-detail--label {
    width: 25%;
    max-width: 200px;
    padding-right: 30px;
    font-family: Brhendrix, sans-serif;
    color: #597843;
    font-size: 1.1em;
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: 0.025em;
    text-transform: uppercase;
}

.tour-detail--info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    top: -2px;
}

.tour-detail--container {
    margin-bottom: 2em;
}

.tour-gallery {
    position: relative;
    overflow: hidden;
    margin-bottom: 4em;
    background-color: transparent;
}

.tour-gallery--item {
    padding: 0.5%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 30%;
    -ms-flex: 1 0 30%;
    flex: 1 0 30%;
}

.tour-gallery--item.top-row {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
}

.tour-gallery--list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@media only screen and (max-width: 1280px) {
	.tour-gallery--list .gallery-item-img {
		height: 18em;		
	}
}

@media only screen and (max-width: 991px) {
	.tour-gallery--item,
	.tour-gallery--item.top-row {
    flex: 1 0 50%;
	}
}
@media only screen and (max-width: 600px) {
	.tour-gallery--list .gallery-item-img {
    height: 15em;
	}
}
@media only screen and (max-width: 479px) {
	.tour-gallery--item,
	.tour-gallery--item.top-row {
    flex: 1 0 100%;
	}
	.tour-gallery--list .gallery-item-img {
    object-fit: unset;
    height: auto;
    width: 100%;
    max-height: none;
    display: block;
 	}
}
.tour-testimonials {
    position: relative;
    overflow: auto;
    margin-top: 0em;
    padding-top: 1em;
    padding-bottom: 5em;
    background-image: url('../images/pattern-design-bg.svg');
    background-position: 50% 0%;
    background-size: 61em;
    background-repeat: repeat-y;
}

.tour-testimonials h2 {
    position: relative;
    /*left: 0px;
    top: 1.5em;
    right: auto;
    bottom: auto;
    display: inline-block;*/
    max-width: 100%;
    margin-bottom: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.7em;
    border-left: 5px solid #60dbdb;
    font-family: Corporativeslab, sans-serif;
    color: #2c3c45;
    font-size: 2.2em;
    text-align: left;
    letter-spacing: 0em;
    text-transform: none;
}

.tour-testimonials p {
    font-size: 2.2rem;
    text-align: left;
}

.h-cite {
    margin-bottom: 3em;
    font-family: Brhendrix, sans-serif;
    color: #2c453c;
    font-size: 1.1em;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-style: normal;
}

.pull-quote {
    position: relative;
    z-index: 1;
    margin-bottom: 3vw;
    padding: 3vw 4vw;
    background-color: #fffef5;
    background-image: url('../images/pullquote-open.svg');
    background-position: 2vw 2vw;
    background-size: 3em;
    background-repeat: no-repeat;
    box-shadow: 0 4px 1em -1.1em #0a0908;
    -webkit-transition: color 300ms ease-out, background-color 300ms ease-out;
    transition: color 300ms ease-out, background-color 300ms ease-out;
    color: #2c3c45;
    font-size: 1.4em;
}
.pull-quote blockquote {
    margin: 0 0 2em;
}

.pull-quote:hover {
    background-color: #2c3c45;
    color: #fffeef;
}

.pull-quote p {
    font-size: 1.1em;
}

/*.tour-testimonials .pull-quote {
    width: 80%;
    max-width: 27em;
}

.tour-testimonials .pull-quote:nth-child(odd) {
    float: left;
}

.tour-testimonials .pull-quote:nth-child(even) {
    float: right;
}*/

.tour-testimonials .pull-quote {
    width: 100%;
    max-width: 100%;
}
.tour-testimonials .pull-quote:nth-child(odd),
.tour-testimonials .pull-quote:nth-child(even) {
    float: none;
}

/*@media only screen and (max-width: 1280px) {
    
}*/

.tour-header--highlights {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 75em;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    color: #fff;
}

.tour-highlight--item {
    padding-right: 1.5em;
    padding-left: 1.5em;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
}

.button-mobile-availability {
    padding: 0.3em 0.5em;
    border-style: solid;
    border-width: 2px;
    border-color: #f2ca61;
    background-color: #f2ca61;
    -webkit-transition: background-color 400ms ease;
    transition: background-color 400ms ease;
    color: #2c3c45;
    font-size: 1.4em;
    text-align: center;
    text-decoration: none;
}

.button-mobile-availability:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
    color: #fff;
}

.button-mobile-availability.sticky {
    display: none;
    width: 100%;
    border-radius: 0px;
    position: sticky;
    top: 0;
    z-index: 100;
}

.social-share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
}

.social-share-icon--link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    border-radius: 100%;
    background-color: #f2ca61;
    background-image: none;
    color: #2c3c45;
    font-size: 1.3em;
    line-height: 1;
    text-decoration: none;
}

.social-share-icon--link:hover {
    background-color: #60dbdb;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#60dbdb), to(#60dbdb));
    background-image: linear-gradient(180deg, #60dbdb, #60dbdb);
    color: #fff;
}

.tour-header--overlay {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 2;
    display: block;
    background-color: rgba(21, 29, 33, 0.5);
}

.tours-wrap {
    display: -ms-grid;
    display: grid;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 0em;
    justify-items: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    grid-auto-columns: minmax(50%, 1fr);
    grid-column-gap: 2.2vw;
    grid-row-gap: 2.2vw;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.tour-item {
    box-shadow: 0 1px 1.2em -1.1em #403933;
}

.tour-item a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fffeef;
    background-image: none;
    text-decoration: none;
}

.tour-item--link {
    background-color: #fff;
}

.tour-item--image {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: max-width 300ms ease-out, height 300ms ease-out, width 300ms ease-out, -webkit-transform 300ms ease-out;
    transition: max-width 300ms ease-out, height 300ms ease-out, width 300ms ease-out, -webkit-transform 300ms ease-out;
    transition: transform 300ms ease-out, max-width 300ms ease-out, height 300ms ease-out, width 300ms ease-out;
    transition: transform 300ms ease-out, max-width 300ms ease-out, height 300ms ease-out, width 300ms ease-out, -webkit-transform 300ms ease-out;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
}

.tour-item--header {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 50vh;
    max-height: 25em;
    min-height: 20em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #d0d1d4;
}

.tour-item--title {
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    /*height: 100%;*/
    margin-bottom: 0px;
    color: #fffeef;
    font-size: 1.9em;
    line-height: 1.2;
    font-weight: 500;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.16);
}

.tour-item--content {
    padding: 1.8em 2.2em 0em;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.tour-item--content p {
    margin-bottom: 0em;
    font-weight: 400;
}

.tour-item--content a {
    text-decoration: none;
}

.tour-item--footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0.25em 2.2em 1em;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.tour-item--meta {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 1.8em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.tour-more {
    padding: 0em;
    text-transform: uppercase;
}

.tour-more p {
    font-size: 1.8rem;
}

.tour-item--read-more {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.3em;
    padding: 1.4em 2.5em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6em;
    background-color: #f2ca61;
    font-size: 0.8em;
}

.tour-item--read-more:hover {
    background-color: #60dbdb;
    color: #fff;
}

.tour-item--amount {
    font-family: Brhendrix, sans-serif;
    color: #4d6c24;
    font-size: 0.8em;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.tour-item--title-block {
    position: relative;
    width: 100%;
    padding-right: 2.2em;
    padding-bottom: 1.5em;
    padding-left: 2.2em;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.tour-item--image-block {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.tour-item--overlay {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    background-color: rgba(21, 29, 33, 0.35);
}

.breadcrumbs {
    position: relative;
    z-index: 10;
    display: inline-block;
    margin-bottom: 1em;
    padding-right: 0.7em;
    padding-left: 1.2em;
    background-color: rgba(242, 202, 97, 0.78);
}

.breadcrumbs a {
    position: relative;
    top: -1px;
    display: inline-block;
    margin-right: 0.5em;
    font-family: Brhendrix, sans-serif;
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-transform: uppercase;
}

.breadcrumbs p {
    color: #000;
}

.breadcrumbs.light {
    padding: 0em 0.2em 0em 0.7em;
    background-color: rgba(242, 202, 97, 0.78);
    color: #fff;
}

.light p {
    color: #fff;
}

.light a {
    margin-right: 0.5em;
    padding-right: 1.3em;
    background-image: url('../images/icon-arrow-breadcrumb.svg');
    background-position: 100% 50%;
    background-size: 0.35em;
    background-repeat: no-repeat;
    font-family: Brhendrix, sans-serif;
    color: #2c3c45;
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.footer-chat-block {
    position: fixed;
    left: auto;
    top: auto;
    right: 20px;
    bottom: 20px;
    z-index: 200;
    width: 4.5em;
    height: 4.5em;
    padding: 0.8em;
    color: #60dbdb;
}

.footer-chat-block:hover {
    color: #f2ca61;
}

.top {
    position: fixed;
    left: 15px;
    top: auto;
    right: auto;
    bottom: 10px;
    z-index: 200;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    background-color: #60dbdb;
    color: #faf7ed;
    text-decoration: none;
}

.contact-form--message {
    min-height: 220px;
    margin-bottom: 30px;
}

.contact-blocks {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 85%;
    max-width: 120em;
    margin: 3em auto 5em;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.contact-details-block {
    position: relative;
    margin-right: 5em;
    padding: 0em 2.8em 3em 0em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 28em;
    -ms-flex: 0 28em;
    flex: 0 28em;
    border-right: 2px solid rgba(78, 120, 48, 0.5);
}

.contact-form-block {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.location-block {
    background-image: url('../images/icon-directions.svg');
    background-position: 0px 3px;
    background-size: 1.2em;
    background-repeat: no-repeat;
}

.contact-address p {
    position: relative;
    margin-bottom: 1.6em;
    padding-left: 2.3em;
}

.contact-email {
    position: relative;
    margin-bottom: 0em;
    padding-left: 2.3em;
    background-image: url('../images/icon-email.svg');
    background-position: 0% 50%;
    background-size: 1.2em;
    background-repeat: no-repeat;
}

.phone-block {
    margin-top: 1.2em;
    padding-bottom: 1.5em;
    background-image: url('../images/icon-phone.svg');
    background-position: 0px 0px;
    background-size: 1.2em;
    background-repeat: no-repeat;
}

.contact-phone-us {
    position: relative;
    margin-bottom: 0em;
    margin-left: 1em;
}

.contact-hours--label,
.contact-hours p {
    position: relative;
    margin-bottom: 10px;
    padding-left: 2.3em;
}

.contact-page-form {
    margin-top: 30px;
    margin-bottom: 0px;
}

.tours-cats-wrap {
    display: -ms-grid;
    display: grid;
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 0.2vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-flow: row dense;
    grid-auto-columns: 1fr;
    grid-column-gap: 0.2vw;
    grid-row-gap: 0.2vw;
    grid-template-columns: repeat(auto-fit, minmax(24em, 1fr));
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.tour-category a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    text-decoration: none;
}

.tour-category--link {
    background-color: #fff;
}

.tour-category--header {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #d0d1d4;
}

.tour-category--title-block {
    position: absolute;
}

.tour-category--title {
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    height: 100%;
    margin-bottom: 0px;
    padding: 0.7em 2.6em 0.7em 1.4em;
    background-color: rgba(242, 202, 97, 0.9);
    background-image: url('../images/arrow-right.svg');
    background-position: 93% 50%;
    background-size: 1em;
    background-repeat: no-repeat;
    font-family: Corporativeslab, sans-serif;
    color: #2c3c45;
    font-size: 1.3em;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: none;
}

.tour-category--image-block {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 100%;
}

.tour-category--overlay {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    background-color: rgba(16, 22, 26, 0.19);
    -webkit-transition: background-color 300ms ease-out;
    transition: background-color 300ms ease-out;
}

.tour-category--overlay:hover {
    background-color: rgba(23, 55, 77, 0.4);
}

.tour-category--image {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 300ms ease-out;
    transition: -webkit-transform 300ms ease-out;
    transition: transform 300ms ease-out;
    transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}

.tour-category--footer {
    display: none;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #e4e5e8;
}

.tour-category--price {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.tour-category--read-more {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
}

.tour-category--amount {
    padding: 6px 15px;
    color: #000;
    line-height: 1.2;
}

.tour-cateogry--more {
    padding: 10px 15px;
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
}

.tour-cateogry--more p {
    font-size: 1.8rem;
}

.tour-faqs {
    margin-top: 6.5em;
    margin-bottom: 6.5em;
}

.cat-overview {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0em;
    /*padding-bottom: 2em;*/
    padding-left: 0em;
}

.section-inner {
    width: 90%;
    max-width: 60em;
    margin-right: auto;
    margin-bottom: 3em;
    margin-left: auto;
    padding-bottom: 2em;
}
.section-inner#overview {
    padding-bottom: 0;
}
.section-inner#overview-more {
    padding-bottom: 0;
}

.section-inner.section-wide {
    max-width: 80em;
    margin-bottom: 4em;
}

.cat-highlights {
    margin-bottom: 4em;
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 2.8em;
    column-gap: 2.8em;
}

.cat-highlights li {
    margin-bottom: 1em;
}

.show-more-block {
    width: 100%;
    border-top: 3px none rgba(126, 121, 111, 0.2);
}

.show-more-toggle {
    width: 100%;
    padding: 0px;
    white-space: normal;
}

.show-more-content {
    width: 100%;
}

.show-more-content.w--open {
    position: static;
    background-color: transparent;
}

.h-tours-wrap {
    position: relative;
    z-index: 1;
    display: -ms-grid;
    display: grid;
    width: 93vw;
    max-width: 120em;
    margin-right: auto;
    margin-bottom: 4em;
    margin-left: auto;
    padding-right: 3em;
    padding-left: 3em;
    justify-items: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    grid-auto-columns: minmax(50%, 1fr);
    grid-column-gap: 2.5vw;
    grid-row-gap: 2vw;
    grid-template-columns: repeat(auto-fit, minmax(17em, 1fr));
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    color: #2c453c;
}

.jump-links {
    position: relative;
    display: -ms-grid;
    display: grid;
    width: auto;
    max-width: 60em;
    margin-top: 3em;
    margin-bottom: 1em;
    margin-right: auto;
    margin-left: auto;
    padding: 0.5em 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(6.5em, 1fr));
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-style: solid;
    border-width: 2px;
    border-color: #f2ca61;
    border-radius: 20px;
    background-color: #fffeef;
    font-size: 1em;
}

.index-video {
    margin-bottom: 2em;
}

.jump-link {
    width: 100%;
    padding: 0.2em 1.5em;
    border-right: 2px solid #f2ca61;
    font-family: Brhendrix, sans-serif;
    font-size: 0.9em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.jump-link:hover {
    background-color: #f2ca61;
}

.span {
    display: inline-block;
    -webkit-transform: skew(20deg, 0deg);
    -ms-transform: skew(20deg, 0deg);
    transform: skew(20deg, 0deg);
}

.hide-on-desktop {
    display: none;
}

.dropdown-link {
    background-image: none;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    position: relative;
    vertical-align: top;
    text-decoration: none;
    display: block;
    width: 100%;
}

.dropdown-link:hover {
    background-image: none;
    color: #60dbdb;
}

.dropdown-link.w--current {
    color: #60dbdb;
}

.h-contact--link {
    background-image: none;
}

.h-contact--link:hover {
    background-image: none;
}

.h-associates {
    position: relative;
    background-color: rgba(255, 189, 128, 0.25);
}

.h-logos-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 0.8em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 18em;
    -ms-flex: 1 0 18em;
    flex: 1 0 18em;
    color: #2c3c45;
    text-align: center;
}

.associated-logo {
    width: auto;
    max-height: 6em;
}

.h2-associates {
    width: 100%;
    margin-bottom: 1.35em;
    color: #2c3c45;
    font-size: 1.6em;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.075em;
    text-transform: uppercase;
}

.tours-intro p {
    max-width: 47em;
    margin-bottom: 2.5em;
}

.wp-block-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1vw;
    padding-bottom: 1vw;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wp-block-columns.inverse-color {
    padding-right: 1vw;
    padding-left: 1vw;
    background-color: #2c453c;
    color: #faf7ed;
}

.gfield_label-2 {
    margin-bottom: 10px;
}

.gfield-2 {
    margin-bottom: 25px;
}

.form_sublabel_below-2 {
    margin-top: 0px;
    margin-bottom: 15px;
    padding-top: 0px;
    font-weight: 400;
    text-transform: none;
}

.p-blockquote {
    font-size: 1.5em;
}

.wp-block-column {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.coldummycolor {
    background-color: #d4d4d4;
    padding: 1.5em;
}

.is-style-intro,
.intro {
    margin-bottom: 1.7em;
    font-size: 1.5em;
}

.colorblocks {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.colorbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    border-style: solid;
    border-width: 4px;
    border-color: #fff;
    box-shadow: 0 0 0 1px #d4d4d4;
    color: #fff;
}

.colorbox.green {
    background-color: #597843;
}

.colorbox.offwhite {
    background-color: #faf7ed;
    color: #000;
}

.colorbox.forestgreen {
    margin-left: 0px;
    background-color: #2c453c;
}

.colorbox.slateblue {
    background-color: #2c3c45;
}

.colorbox.aqua {
    background-color: #60dbdb;
}

.colorbox.yellow {
    background-color: #f2ca61;
}

hr,
.wp-block-separator {
    height: 20px;
    max-width: 250px;
    margin: 40px auto;
    clear: both;
    border-right-style: none;
    border-right-width: 0px;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-left-style: none;
    border-left-width: 0px;
    background-image: url('../images/line-sep.svg');
    background-position: 0% 50%;
    background-size: 280px;
    background-repeat: repeat-x;
    border-top: 0;
}
.wp-block-separator.spacer,
.spacer {
	background-image:none;
	margin:0;
	height: 3vw;
}

.wp-block-separator.is-style-wide,
hr.is-style-wide {
    width: 100%;
    max-width: 100%;
}

.wp-block-separator.is-style-dots,
hr.is-style-dots {
    height: 3px;
    border-top: 6px dotted #61dadb;
    background-image: none;
    background-size: auto;
    color: #0f8ad1;
    text-align: center;
}

.lists {
    margin-bottom: 2em;
}

.stylename {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #d4d4d4;
    border-bottom: 0px none #d4d4d4;
    font-size: .95em;
    text-transform: uppercase;
}

.styletitle {
    margin-top: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d4d4d4;
    font-size: 1.2em;
    text-transform: uppercase;
}

.styletitle.forms-sep {
    margin-top: 6em;
}

.gform_wrapper-2 {
    margin-bottom: 0px;
    padding-bottom: 2em;
    text-align: left;
}

.checkbox-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact-phone-guat {
    position: relative;
    margin-bottom: 0em;
    margin-left: 1em;
}

.aside-tel {
    margin-top: 1.4em;
    font-style: italic;
    font-size:1em;
}
.aside-tel a { white-space: nowrap; }

.show-more-text {
    margin-bottom: 1.2em;
}

.show-more-text p {
    font-size: 1.4em;
    line-height: 1.7;
}

.show-more-btn {
    display: inline-block;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(65%, transparent), color-stop(0, rgba(97, 219, 219, 0.5)), color-stop(100%, rgba(97, 219, 219, 0.5)), to(transparent));
    background-image: linear-gradient(180deg, transparent 65%, rgba(97, 219, 219, 0.5) 0, rgba(97, 219, 219, 0.5) 100%, transparent);
    font-family: Brhendrix, sans-serif;
    font-size: 0.95em;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.show-more-btn:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(65%, transparent), color-stop(0, #f2ca61), color-stop(100%, #f2ca61), to(transparent));
    background-image: linear-gradient(180deg, transparent 65%, #f2ca61 0, #f2ca61 100%, transparent);
}

@media only screen and (max-width: 1279px) {
	.show-more-text p {
		font-size: 18px;
	}
}

.h2 {
    margin-bottom: 0.75em;
    font-family: Brhendrix, sans-serif;
    font-size: 1.8em;
    line-height: 1.2;
    font-weight: 700;
}

.h2.available-tours-label {
    position: relative;
    z-index: 1;
    display: block;
    width: auto;
    max-width: 13em;
    margin-right: auto;
    margin-bottom: -0.75em;
    margin-left: auto;
    padding: 0.2em;
    background-color: #60dbdb;
    -webkit-transform: skew(-20deg, 0deg);
    -ms-transform: skew(-20deg, 0deg);
    transform: skew(-20deg, 0deg);
    font-size: 1.6em;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.h3 {
    margin-top: 0.4em;
    margin-bottom: 0.2em;
    font-family: Brhendrix, sans-serif;
    font-size: 1.6em;
    line-height: 1.3;
    font-weight: 700;
}

.h4 {
    margin-top: 0.4em;
    margin-bottom: 1em;
    font-family: Corporativeslab, sans-serif;
    font-size: 1.2em;
    line-height: 1.4;
    font-weight: 700;
}

.h5 {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    font-family: Corporativeslab, sans-serif;
    font-size: 1em;
    line-height: 1.4;
    font-weight: 700;
}

.text-span {
    display: inline-block;
    -webkit-transform: skew(20deg, 0deg);
    -ms-transform: skew(20deg, 0deg);
    transform: skew(20deg, 0deg);
}

.left {
    position: relative;
    margin-bottom: 2em;
    padding: 2em 4em;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 27em;
    -ms-flex: 1 0 27em;
    flex: 1 0 27em;
    border-radius: 1.1em;
    background-color: #fff;
    box-shadow: 0 1px 1.2em -1.2em #000;
}

.right {
    padding: 0em 4em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 30em;
    -ms-flex: 0 30em;
    flex: 0 30em;
    border-radius: 0.1em;
}

.calendar_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    font-size: 0.9em;
}
#pax {
    display: flex;
    float: right;
}
i.fa.fa-male {
    margin-left: -4.09px;
}
.booking-cal-h2 {
    background-image: url('../images/line-sep.svg');
    background-position: 50% 50%;
    background-size: auto;
    background-repeat: repeat-x;
    font-size: 1.8em;
    text-align: center;
}

.text-span-2 {
    padding-right: 0.8em;
    padding-left: 0em;
    background-color: #fefcdf;
}

.bookings-subtext {
    margin-bottom: 0.5em;
    font-size: 1.2em;
}

/* Calendar Page Calendar */
.booking-cal #calendar {
    border-radius: 1em;
    background: #fff !important;
    box-shadow: 0 1px 1.2em -1.2em #000;
    padding: 4em;
}
.booking-cal .left #calendar {
	padding: 0;
	box-shadow: none;
	border-radius: 0;
}

.fc-day-grid-event {
    margin: 2px 2px 0 !important;
    padding: 2px 3px !important;
}
.fc-event {
	border:0 !important;
	border-radius: 0 !important;
	background-image: none !important;
	background-color: #2abbbb !important;
  color: #fff !important;
  font-weight: normal !important;
  font-size: 1em;
}
.fc-event:hover {
	background-image: none !important;
}


/** Booking Modal **/

.modal {
    position: fixed;
    /*top: 0;
    right: 0;
    bottom: 0;
    left: 0;*/
    display: none;
    overflow: visible;
    outline: 0;
    z-index: 99999;
    background: #fffeef;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40vw;
    min-width: 260px;
    padding: 2vw;
    box-shadow: 0 1px 1.2em -1.1em #000;
    border: .5vw inset #ffcd57;
}
.modal-dialog:after {
    content: '';
    background-image: url('../images/flying-quetzal.svg');
    width: 17em;
    height: 10em;
    display: block;
    right: -36px;
    bottom: -30px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 9em;
}

.modal button.close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 3em;
    line-height: .75;
    background: #f2c961;
    color: #fffeee;
}

.modal .btn.btn-default {
    font-size: .9em;
    padding: .9em 2em;
}

h4.modal-title {
    font-size: 1.8em;
}

.contact-map {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 85%;
    max-width: 120em;
    margin: 3em auto 5em;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.map-embed {
    width: 100%;
    border: 6px solid #fff;
}

.text-sm {
    display: inline-block;
    font-size: 0.7em;
    font-weight: 700;
}

.tiered-pricing-toggle {
    padding: 0px;
}

.social-share-icon {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1.1em;
    height: 100%;
    max-height: 1.1em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    line-height: 0;
}

.social-icon {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 2.8em;
    height: 2.4em;
    max-width: 40px;
    padding: 0.5em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    line-height: 0.2;
}

.social-icon.social-icon-fb {
    width: 1.9em;
}

.oto-logo-footer {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 1em;
}

.tippy-top-icons-block {
    padding-right: 1em;
    padding-left: 1em;
    font-size: 0.75em;
}

.icon-trip-advisor {
    width: 11em;
    margin-top: 0.5em;
    margin-right: auto;
    margin-left: auto;
}

.hours-block {
    padding-bottom: 1.5em;
    background-image: url('../images/icon-hours.svg');
    background-position: 0px 2px;
    background-size: 1.2em;
    background-repeat: no-repeat;
}

.share-label {
    width: 100%;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    font-family: Corporativeslab, sans-serif;
    font-size: 1em;
    line-height: 1.4;
    font-weight: 700;
}

.icon-star {
    width: 1.4em;
    margin-right: auto;
    margin-bottom: 0.7em;
    margin-left: auto;
    color: #f2ca61;
}

.tour-menu-bullet {
    width: 0.6em;
    margin-right: 0.5em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.tour-menu {
    margin-top: 0px;
    margin-bottom: 1.7em;
    padding-left: 0px;
    list-style-type: none;
}

.tour-menu li {
    padding-top: .125rem;
    padding-bottom: .125rem;
    padding-left: 1.5em;
    border-bottom: 1px solid #e8e6cb;
    background-image: url('../images/breadcrumb-arrow.svg');
    background-position: 3px 50%;
    background-size: 0.45em;
    background-repeat: no-repeat;
}

.tour-menu--link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0.9vh;
    padding-bottom: 0.9vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Brhendrix, sans-serif;
    font-weight: 700;
    font-size: .9em;
}

.tour-menu--link:hover {
    color: #60dbdb;
}

.contact-phone-whatsapp {
    position: relative;
    margin-bottom: 0em;
    margin-left: 1em;
}

.contact-phone {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0.5em;
    padding-left: 2.8em;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.whatsapp {
    width: 1.8em;
    padding: 5px;
    border-radius: 0.3em;
    background-color: #0eb917;
    color: #fff;
    line-height: 0.8;
}

.flag {
    position: relative;
    top: 3px;
    width: 1.9em;
}

.whatsapp-label {
    font-size: 0.75em;
    font-style: normal;
}

.tour-item--sep {
    padding-right: 0.5em;
    padding-left: 0.5em;
    font-family: Brhendrix, sans-serif;
    color: #4d6c24;
    font-size: 0.8em;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.tour-item--length {
    font-family: Brhendrix, sans-serif;
    color: #4d6c24;
    font-size: 0.8em;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.tour-item--skill {
    font-family: Brhendrix, sans-serif;
    color: #4d6c24;
    font-size: 0.8em;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.is-style-inverse-outline {
    border-color: #faf7ed;
    background-color: transparent;
    color: #faf7ed;
}

.is-style-inverse-outline:hover {
    border-color: #60dbdb;
    background-color: #60dbdb;
    background-image: none;
    color: #faf7ed;
}

.h-benefits--why {
    margin-right: 0em;
    padding-right: 3vw;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 25em;
    -ms-flex: 1 0 25em;
    flex: 1 0 25em;
    border-right: 2px none #f2ca61;
    background-image: url('../images/yellow-diamond-vert.svg');
    background-position: 100% 0%;
    background-size: 1em;
    background-repeat: repeat-y;
}

.h-benefits--what {
    margin-left: 0em;
    padding-left: 3vw;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 25em;
    -ms-flex: 1 0 25em;
    flex: 1 0 25em;
}

.footer-byline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
    font-weight: 400;
    text-decoration: none;
}

.footer-byline:hover {
    background-image: none;
}

.tour-featured-img,
.tour-item--image,
.tour-category--image {
    object-fit: cover;
}

.tour-item:hover img,
.tour-category a:hover img {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    /* IE 9 */
    -moz-transform: scale(1.1);
    /* Firefox */
    -webkit-transform: scale(1.1);
    /* Safari and Chrome */
    -o-transform: scale(1.1);
    /* Opera */
}

.jump-link:last-of-type {
    border: 0;
}

.custom-tours .entry-content {
    padding-bottom: 0;
}

/* --------------------------------------
* >	Booking Calendar  	
-----------------------------------------*/

/*.booking-cal #calendar {
    max-width: 30em;
}*/

.booking-cal .g-recaptcha {
    margin: 2em 0;
}

.booking-cal #book-now-form {
    margin: 2em 0;
}

.booking-cal #calendar h2 {
    font-size: 1.2em;
    font-family: Brhendrix, sans-serif;
    font-weight: 700;
    text-align: left;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.booking-cal .fc-state-default {
    border: 1px solid;
    background-color: #f1c960;
    background-image: none;
    border-color: #f1c960;
    color: #2b3d45;
    text-shadow: 0 0 black;
    box-shadow: none;
}

.booking-cal .fc-day-header {
    background-color: #2b3d45;
    color: #fff;
    font-family: Brhendrix, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: .9em;
}

.booking-cal .fc-state-default.fc-corner-right {
    border-left: 1px solid white;
}

.booking-cal .fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: #e8e6cb !important;
}

.booking-cal .fc-unthemed .fc-disabled-day {
    background: #ededba;
    opacity: .3;
}

.booking-cal .fc-bgevent {
    background: #52ad8a !important;
    opacity: .3;
}

.booking-cal .calendar_footer {
    margin: 2em 0;
}

.booking-cal p.booked {
    margin-bottom: 0;
}

.booking-cal .booked .square {
    background: #51baba;
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-right: .25em;
}

.booking-cal .unavailable .square {
    background: hsl(56deg 39% 85%);
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-right: .25em;
}

.booking-cal .right h4 {
    clear: both;
    font-family: 'Brhendrix', sans-serif;
    font-size: 1.2em;
}

.booking-cal h4#total_price {
    border-top: 1px solid #e8e6cb;
    border-bottom: 1px solid #e8e6cb;
    padding: .6em 0;
    font-family: Corporativeslab, sans-serif;
    font-size: 1.5em;
}

.booking-cal #book-now.btn {
    width: 100%;
    margin: 0;
}

.booking-cal .booking-result {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    font-weight: 400;
}

.booking-cal .btn.add {
    order: 3;
}

.booking-cal .form-group input:not([type=submit]):not([type=file]) {
    width: 100%;
}

.booking-cal h2 {
    margin-bottom: .5em;
}

.tour-item--sep {
    color: #597843;
}

.tour-item--length {
    color: #597843;
}

.tour-item--skill {
    color: #597843;
}

@media screen and (min-width: 1440px) {
    .tour-inner {
        padding-right: 3em;
        padding-left: 3em;
    }
    .dropdown-links {
        -webkit-column-gap: 50px;
        column-gap: 50px;
    }
}

@media screen and (min-width: 1920px) {
    .tour-inner {
        max-width: 105em;
    }
    .tour-aside {
        -webkit-flex-basis: 23em;
        -ms-flex-preferred-size: 23em;
        flex-basis: 23em;
    }
    .tour-content {
        padding-left: 8em;
    }
    /*.tours-cats-wrap {
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fit, minmax(25em, 24.85%));
    }*/
    .section-inner {
        max-width: 75em;
    }
    .section-inner.section-wide {
        max-width: 100em;
    }
}

@media only screen and (max-width: 1279px) {
    .tour-testimonials h2 {
        position: relative;
        display: block;
        max-width: 11em;
    }
    .tour-inner {
	    max-width: 92%;
    }
    .tour-content {
        padding-left: 3.5em;
    }
}

@media screen and (max-width: 991px) {
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 2.8em;
    }
    .button.h-tours {
        position: absolute;
        left: auto;
        top: auto;
        right: 0%;
        bottom: 0%;
        display: block;
        width: 50%;
        margin-right: 0px;
        margin-bottom: 0em;
        margin-left: 0px;
        border-bottom-style: none;
        border-radius: 0em;
    }
    .button.aside {
        font-size: 0.8em;
    }
    .button.h-hero {
        min-height: 0em;
    }
    .alignnone {
        width: 100%;
    }
    .block-spacer {
        height: 5px;
    }
    .site-header {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 7em;
        background-color: #2c453c;
    }
    .title-area {
        position: relative;
        max-width: 15em;
        padding-right: 0em;
        padding-left: 0em;
    }
    .title-area a {
        padding-right: 1.8em;
        padding-left: 1.8em;
    }
    .oto-logo {
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .menu-toggle {
        position: absolute;
        left: auto;
        top: 0%;
        right: 0%;
        bottom: auto;
        z-index: 500;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 2.56em;
        background-color: #f2ca61;
    }
    .menu-toggle.w--open {
        z-index: 1001;
        background-color: #60dbdb;
    }
    .nav-primary {
        left: auto;
        top: 4.5em;
        right: 0%;
        bottom: auto;
        width: 50%;
        padding-left: 0em;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
        background-color: #2c453c;
        color: #faf7ed;
    }
    .menu-primary {
        position: relative;
        width: 100%;
        height: auto;
        max-width: 100%;
        margin-left: 0em;
        padding: 25px 2em;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
    .menu-item {
        display: block;
        width: 100%;
        padding: 0.4em 0em;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
        text-align: left;
    }
    .menu-item.mega-drop-down {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .menu-item.button {
        position: relative;
        width: auto;
        margin-top: 2em;
        margin-right: 0px;
        margin-left: 0px;
        color: #2c3c45;
        text-align: center;
        border-radius: 2em;
    }
    .menu-item.button:hover {
        color: #faf7ed;
    }
    .submenu-toggle {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-right: 0px;
        margin-left: 0px;
        padding: 0.4em 0em;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .sub-menu.w--open {
        position: relative;
        display: block;
        width: 100%;
        padding-right: 0em;
        padding-left: 0em;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        background-color: transparent;
    }
    .sub-menu.sub-mega-menu.w--open {
        padding: 0em;
    }
    
    
    .icon-angle-down {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        margin-left: 7px;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        font-size: 1.3em;
    }
    .wrap {
        position: relative;
        width: 100vw;
        height: auto;
        background-image: none;
        background-position: 0px 0px;
        background-size: auto;
        background-repeat: repeat;
    }
    .burger-1 {
        width: 2.6em;
        height: 100%;
    }
    .b1-bar1 {
        border-radius: 4em;
    }
    .b1-bar2 {
        border-radius: 4em;
    }
    .b1-middle {
        border-radius: 4em;
    }
    .b1-bar3 {
        border-radius: 4em;
    }
    .tippy-top-icons-block {
        margin-right: 0em;
        margin-left: 0em;
        border-right-style: none;
        border-left-style: none;
    }
    .menu-tippy-top {
        display: none;
    }
    .menu-tippy-top a {
        padding-right: 15px;
        padding-left: 15px;
    }
    .tippy-top-wrap {
        padding-right: 1em;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .dropdown-links {
        width: 100%;
        margin-bottom: 1em;
        padding-right: 0px;
        border-right-style: none;
    }
    .dropdown-links.customize {
        margin-top: 2em;
    }
    .dropdown-menu-links {
        display: block;
        padding-bottom: 12px;
        border-left: 0;
        padding-left: .25em;
    }
    .dropdown-menu-links:first-of-type .dropdown-menu-items {
    	display: block;
		}
    .dropdown-menu-link {
        color: #faf7ed;
    }
    .dropdown-menu-items.two-col-section {
        -webkit-column-count: 1;
        column-count: 1;
    }
    .dropdown-menu-heading {
        padding-right: 0em;
        padding-left: .5em;
        color: #f2ca61;
        font-size: 0.8em;
    }
    .dropdown-menu-heading.button.is-style-outline {
        border-color: #faf7ed;
        color: #faf7ed;
        font-size: 0.7em;
    }
    .dropdown-menu--wrap,
    .sub-mega-menu .dropdown-menu--wrap {
        display: block;
        padding-top: 0em;
    }
    .dropdown-link {
        padding-top: 1.25%;
        padding-bottom: 1.25%;
    }
    
    .inner.h-contact--inner {
        justify-content: flex-start;
        text-align: left;
        align-items: flex-start;
    }
    .h-contact p {
        text-align: left;
    }
    .footer-top-wrap {
        padding-top: 7vw;
        padding-bottom: 0vw;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .footer-area {
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }
    .footer-area.first {
        text-align: left;
    }
    .footer-area.middle {
        text-align: left;
    }
    .footer-area.last {
        margin-bottom: 0em;
        background-position: 50% 0%;
        text-align: left;
    }
    .menu-footer {
        margin-bottom: 4em;
    }
    .menu-footer a {
        text-align: left;
    }
    .social-icons-block {
        padding-top: 0px;
        justify-content: flex-start;
    }
    .entry.about-page {
        background-image: linear-gradient(223deg, rgba(255, 252, 224, 0.65), hsla(0, 0%, 100%, 0)), url('../images/texture-bg1.svg');
        background-position: 0px 0px, 100% 0%;
        background-size: auto, 370px;
        background-repeat: repeat, no-repeat;
    }
    .entry-header {
        padding-bottom: 30px;
    }
    .entry-content {
        padding: 40px;
    }
    .h-hero {
        min-height: 500px;
    }
    .h-tours p {
        width: 90%;
    }
    
    
		.h-tours h3 {
        width: 90%;
        padding-right: 0em;
    }
    .inner.h-company--inner {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .inner.h-tours--inner {
        padding-right: 3em;
        padding-left: 3em;
    }
    .inner.h-testimonials--inner {
        padding-bottom: 50vw;
    }
    .inner.h-hero--inner {
        height: 100%;
        min-height: 0px;
        padding-right: 2em;
        padding-left: 2em;
    }
    .inner.h-associates--inner {
        font-size: 0.7em;
    }
    .h-company--entry {
        margin-bottom: 20px;
        padding-right: 0em;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 400px;
        -ms-flex: 1 0 400px;
        flex: 1 0 400px;
    }
    .h-company--img-block {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 460px;
        -ms-flex: 1 0 460px;
        flex: 1 0 460px;
    }
    .h-company--img {
	    width: 100%;
    }
    .h-tours--item {
        display: block;
        overflow: hidden;
        margin-bottom: 2em;
        -webkit-flex-basis: 200px;
        -ms-flex-preferred-size: 200px;
        flex-basis: 200px;
        font-size: 13px;
    }
    .h-testimonials p {
        font-size: 1.3em;
    }
    .h-why-what h2 {
        max-width: 100%;
    }
    .archive-description {
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(254, 252, 223, 0.5)), to(rgba(254, 252, 223, 0.5))), url('../images/texture-bg1.svg');
        background-image: linear-gradient(180deg, rgba(254, 252, 223, 0.5), rgba(254, 252, 223, 0.5)), url('../images/texture-bg1.svg');
        background-position: 0px 0px, 0px 0px;
        background-size: auto, 35em;
        background-repeat: repeat, no-repeat;
    }
    .archive-description.archive-tours-description {
        margin-top: 2.5em;
    }
    .archive-title.archive-tours-title {
        font-size: 28px;
    }
    .tour-inner {
        padding-right: 0em;
        padding-left: 0em;
	    max-width: 100%;
    }
    .tour-aside {
        padding: 1.3em 1em;
        -webkit-flex-basis: 14em;
        -ms-flex-preferred-size: 14em;
        flex-basis: 14em;
    }
    .tour-content {
        padding-left: 2em;
    }
    .tour-content h2 {
        position: relative;
        top: 0em;
        max-width: 100%;
        font-size: 1.9em;
    }
    .tour-detail--label {
        font-size: 0.9em;
    }
    .pull-quote {
        padding: 2em;
        font-size: 1.2em;
    }
    .tour-item--header {
        height: 280px;
    }
    .contact-blocks {
        padding-right: 0px;
        padding-left: 0px;
    }
    .contact-details-block {
        margin-right: 2.5em;
        padding-right: 2em;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
       
    .gallery-list .gallery-item {
        -webkit-flex-basis: 250px;
        -ms-flex-preferred-size: 250px;
        flex-basis: 250px;
    }
    .h-tours--img {
        position: relative;
        width: 50%;
        height: 22em;
        margin-right: 2em;
        float: left;
    }
    .section-inner {
        margin-bottom: 2em;
        padding-bottom: 1em;
    }
    .h-tours-wrap {
        display: block;
        width: auto;
    }
    .jump-links {
        font-size: 0.9em;
    }
    .hide-on-desktop {
        display: block;
    }
    .gform_footer-2 {
        width: 100%;
    }
    .wp-block-separator.is-style-wide {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .show-more-text {
        font-size: 1.3em;
    }
    .right {
        padding-right: 0em;
        padding-left: 0em;
    }
    .calendar_container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .contact-map {
        padding-right: 0px;
        padding-left: 0px;
    }
    .oto-logo-footer {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }
    .footer-bottom-wrap {
        justify-content: flex-start;
    }
    .h-benefits--why {
        margin-bottom: 1.5em;
        margin-left: 0em;
        border-right-style: none;
        background-image: none;
        background-size: auto;
        background-repeat: repeat;
    }
    .h-benefits--what {
        margin-left: 0em;
        padding-left: 0vw;
    }
    .h-company--img-block {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 460px;
        -ms-flex: 1 0 460px;
        flex: 1 0 460px;
    }
}

@media screen and (max-width: 767px) {
    .button.h-tours {
        position: relative;
        width: 100%;
        max-width: 100%;
    }
    .h-tours h2 {
        font-size: 30px;
    }
    .archive-title {
        font-size: 30px;
        margin-bottom: .25em;
    }
    p.breadcrumbs {
    	margin-bottom: .85em;
		}
    .h-tours--img {
        width: 100%;
        height: 25em;
        margin-bottom: 1.5em;
        float: none;
    }
    .tour-header {
        overflow: visible;
        width: 100%;
        height: auto;
        max-width: 100%;
        min-height: 0px;
        padding-top: 2.8em;
        padding-bottom: 2em;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        border-top: 1px solid #2c453c;
        border-bottom-style: none;
        background-color: #2c453c;
    }
    .tour-title {
        font-size: 30px;
    }
    .tour-header--buttons {
        display: none;
    }
    .tour-featured-img {
        position: relative;
        z-index: 2;
        max-height: 360px;
        margin-bottom: 1em;
    }
    .tour-inner {
        width: 100%;
        padding-top: 0em;
        padding-bottom: 0em;
    }
    .tour-aside {
        position: static;
        width: 100%;
        margin-top: 2em;
        padding: 2em 3em 3em;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }
    .button.aside.btn-booking {
	    display: none;
    }
    .tour-content {
        padding-top: 2em;
        padding-right: 3em;
        padding-left: 3em;
    }
    .tour-header--highlights {
        margin-top: 20px;
        margin-bottom: 0px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        color: #2c3c45;
    }
    .tour-highlight--item {
        margin-bottom: 20px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
        text-shadow: none;
    }
    .tour-detail--label {
        width: 100%;
				margin-bottom: .5em;
    }
    .tour-details {
    	margin-bottom: 4em;
		}
    .button-mobile-availability.sticky {
        display: block;
    }
    .tour-header--overlay {
        left: 0%;
        top: auto;
        right: 0%;
        bottom: 0%;
        height: 70%;
        background-color: #fffeef;
    }
    .tours-wrap {
        grid-row-gap: 5vw;
    }
    .tour-expectations {
	    margin-bottom: 0;
    }
    .tour-testimonials {
	    padding-top: 0;
    }
    .breadcrumbs.light {
        background-color: #f2ca61;
    }
    .contact-blocks {
        display: block;
    }
    .contact-details-block {
        margin-right: 0em;
        padding-right: 0em;
        border-right-style: none;
    }
    .section-inner {
        width: 85%;
    }
    .cat-highlights {
        margin-bottom: 0em;
        -webkit-column-count: 1;
        column-count: 1;
    }
    .h-tours-wrap {
        padding-right: 0em;
        padding-left: 0em;
    }
    .index-video {
        margin-bottom: 0em;
    }
    .colorblocks {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .right {
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }
    .tippy-top-icons-block {
        padding-right: 0em;
        padding-left: 0em;
    }
    .tour-menu {
        display: none;
    }
    .h-benefits--why {
        padding-right: 0vw;
    }
    .h-company--img {
	    height: 20em;
    }
    .footer-area.last {
	    margin-top: 4em;
    }
    .footer-top-wrap {
	    padding-left: 2em;
	    padding-right: 2em;
    }
}

@media screen and (max-width: 479px) {
    .button.h-hero {
        padding-right: 2em;
        padding-left: 2em;
        font-size: 13px;
    }
    .title-area {
        max-width: 14em;
    }
    .nav-primary {
	    width: 100%;
      font-size: 15px;
    }
    .icon-angle-down {
	    margin-left: 14px;
    }
    .sub-menu.w--open {
        display: block;
        padding-right: 0em;
        padding-left: 0em;
    }
    .sub-menu.sub-mega-menu.w--open {
        padding-right: 0em;
        padding-left: 0em;
    }
    .footer-top-wrap,
    .footer-bottom-wrap {
        padding-left: 1.2em;
        padding-right: 1em;
        display: block;
        text-align: left;
    }
    .content.archive-tours-content {
        overflow: hidden;
    }
    .entry-content {
    	padding: 20px 40px;
		}
    .h-hero h1 {
        font-size: 32px;
        text-align: left;
    }
    .h-tours h2 {
        font-size: 25px;
    }
    .inner {
        padding-right: 1.5em;
        padding-left: 1.5em;
    }
    .inner.h-tours--inner {
        padding-right: 2.3em;
        padding-left: 2.3em;
    }
    .inner.h-contact--inner {
        font-size: 13px;
    }
    .inner.h-hero--inner {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .h-company--entry {
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }
    .archive-description {
        padding-right: 1.5em;
        padding-left: 1.5em;
    }
    .archive-title.archive-tours-title {
        font-size: 22px;
    }
    .wrap {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .h-tours--img {
        height: 20em;
    }
    .tippy-top-wrap {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .tippy-top-phone {
    	font-size: 14px;
		}
    .tour-content {
        padding-right: 1.6em;
        padding-left: 1.6em;
    }
    .tour-detail--item {
        display: block;
    }
    .jump-links {
        padding-right: 0.6em;
        padding-left: 0.6em;
        grid-template-columns: repeat(auto-fit, 50%);
        border-radius: 0px;
    }
    .jump-link.w--current {
        padding-right: 0em;
        padding-left: 0em;
    }
    .h2.available-tours-label {
        font-size: 16px;
    }
    .left {
        width: 100%;
        padding-right: 1.7em;
        padding-left: 1.7em;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }
    .right {
        width: 100%;
    }
    .tippy-top-icons-block {
        display: none;
    }
    .h-benefits--why {
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }
    .h-benefits--what {
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }
    .h-company--img-block {
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
    .jump-link:nth-child(2n) {
        border-right: 0;
    }
    .h-logos-block {
	    margin-top: 16px;
	    margin-bottom: 16px;
    }
    .gallery-list .gallery-item-img {
	    height: 18em;
    }
    .inner.h-testimonials--inner {
	    padding-left: 0;
	    padding-right: 0;
    }
}

.dropdown-links.activities {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
}

.dropdown-links.customize {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
}


/* Block Editor */

@media only screen and (min-width: 1024px) {
    .alignwide {
        margin-left: -100px;
        margin-right: -100px;
        max-width: 100vw;
    }
}

.alignfull {
    margin-left: calc(-100vw / 2 + 100% / 2);
    margin-right: calc(-100vw / 2 + 100% / 2);
    max-width: 100vw;
}
.alignfull img {
	width: 100%;
}

.wp-block-columns,
.block-columns {
    display: flex;
    margin-bottom: 2em;
    flex-wrap: wrap;
}

.wp-block-columns:first-of-type,
.block-columns:first-of-type {
    margin-top: 0;
}

@media (min-width: 782px) {
    .wp-block-columns,
    .block-columns {
        flex-wrap: nowrap;
    }
}

.wp-block-columns.has-background,
.block-columns.has-background {
    padding: 3em;
}

.wp-block-column,
.block-column {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
}

@media (min-width: 600px) and (max-width: 781px) {
    .wp-block-column,
    .block-column {
        flex-basis: calc(50% - 1em) !important;
        flex-grow: 0;
    }
    .wp-block-column:nth-child(2n),
    .block-column:nth-child(2n) {
        margin-left: 2em;
    }
}

@media (min-width: 782px) {
    .wp-block-column,
    .block-column {
        flex-basis: 0;
        flex-grow: 1;
    }
    .wp-block-column[style],
    .block-column[style] {
        flex-grow: 0;
    }
    .wp-block-column:not(:first-child),
    .block-column:not(:first-child) {
        margin-left: 2em;
    }
}

@media (min-width: 992px) {
    .wp-block-column:not(:first-child),
    .block-column:not(:first-child) {
        margin-left: 4em;
    }
}

@media (max-width: 599px) {
    .wp-block-column,
    .block-column {
        flex-basis: 100% !important;
    }
}


/* Images */

.block-image .aligncenter,
.block-image .alignleft,
.block-image .alignright,
.block-image.is-resized,
.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.is-resized,
.aligncenter,
.alignleft,
.alignright {
    display: table;
}

.block-image .aligncenter,
.wp-block-image .aligncenter,
.aligncenter {
    margin-left: auto;
    margin-right: auto;
}

.block-image .alignleft,
.wp-block-image .alignleft,
.alignleft {
    float: left;
}

.block-image .alignright,
.wp-block-image .alignright,
.alignright {
    float: right;
}

figure.alignright {
    margin-left: 1em;
}
figure.alignleft {
    margin-right: 1em;
}

.block-image .alignleft,
.block-image .alignright,
.wp-block-image .alignleft,
.wp-block-image .alignright {
    max-width: 50%;
}

@media (max-width: 767px) {
    .block-image .alignleft,
    .block-image .alignright,
    .block-image .aligncenter,
    .wp-block-image .alignleft,
    .wp-block-image .alignright,
    .wp-block-image .aligncenter,
    .aligncenter,
    .alignleft,
    .alignright {
        width: 100%;
        max-width: 100%;
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
}


/* --------------------------------------
* > Stop font scaling  	
-----------------------------------------*/

@media only screen and (min-width: 992px) and (max-width: 1279px) {
    body {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1680px) {
    body {
        font-size: 15px;
    }
}

@media only screen and (min-width: 1920px) {
    body {
        font-size: 18px;
    }
}


/* --------------------------------------
* > Blog Styles  	
-----------------------------------------*/
.archive-description.blog-index-header {
  margin-bottom: 0em;
  padding-bottom: 0em;
  padding-left: 0;
}

.archive-title.blog-index-title {
  margin-bottom: 0.3em;
}

.blog-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100em;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1em;
  padding-bottom: 5em;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255,252,213,0.0)), color-stop(50%, rgba(255,252,213,0.0)), color-stop(0, rgba(157, 216, 215, 0.3)), color-stop(50.1%, rgba(157, 216, 215, 0.3)), color-stop(0, rgba(255,252,213,0.0)), color-stop(100%, rgba(255,252,213,0.0)), to(#fff));
	background-image: linear-gradient(90deg, rgba(255,252,213,0.0), rgba(255,252,213,0.0) 50%, rgba(157, 216, 215, 0.3) 0, rgba(157, 216, 215, 0.3) 50.1%, rgba(255,252,213,0.0) 0, rgba(255,252,213,0.0) 100%, #fff);
}

.blog-title {
  width: 100%;
  font-size: 2.1em;
  margin-bottom: 0.25em;
}

.blog-post {
  position: relative;
  width: 100%;
}

.blog-post.blog-link {
  margin-top: 3em;
  margin-bottom: 3em;
}

.blog-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #001d26;
  text-decoration: none;
}

.blog-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1.5em;
  margin-left: 1.5em;
  padding: 3em 7em 3em 4em;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 252, 214, 0.75)), to(rgba(255, 252, 214, 0.75))), url('../images/texturetastic_gray_2X.jpg');
  background-image: linear-gradient(180deg, rgba(255, 252, 214, 0.75), rgba(255, 252, 214, 0.75)), url('../images/texturetastic_gray_2X.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, 476px;
}

.blog-meta {
  position: relative;
  display: inline-block;
  margin-bottom: 1.3em;
  padding: 0.25em 0.65em;
  background-color: #39bfbf;
  font-family: Brhendrix, sans-serif;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.blog-meta.blog-single-meta {
  margin-bottom: 0em;
  background-color: transparent;
  color: #2c3c45;
}

.blog-featured-img {
  position: relative;
  height: 26em;
  min-height: 40vmin;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 42%;
  -ms-flex: 0 42%;
  flex: 0 42%;
}

.excerpt {
  margin-bottom: 0px;
  font-weight: 400;
}

.blog-image {
  position: absolute;
  left: 0%;
  top: 50%;
  right: auto;
  bottom: 0%;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  box-shadow: 16px 16px 0 0 #f2ca61;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -o-object-fit: cover;
  object-fit: cover;
}

.archive-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 75px auto 0px;
  padding: 30px 60px 30px 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  background-color: #38574b;
  font-family: Brhendrix, sans-serif;
  text-align: center;
  list-style-type: none;
}

.archive-pagination a.pagination-next,
.archive-pagination a.pagination-previous {
	width: auto !important;
  background: transparent !important;
  margin: 0 1em !important;
  border-radius: 0 !important;
  border: 0;
}

.pagination-previous {
  color: #fff;
  font-weight: 500;
}

.pagination-omission {
  padding-right: 10px;
  padding-left: 10px;
  color: #fff;
}

.pagination-next {
  background-color: transparent;
  color: #fff;
  font-weight: 500;
}

.pagination-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 2.2em;
  height: 2.2em;
  margin-right: 0.3em;
  margin-left: 0.3em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 100%;
  color: #fff;
  font-size: 0.9rem;
}

.pagination-page:hover {
  background-color: #fff;
  color: #2c3c45;
}

.pagination-page.active {
  border-color: #60dbdb;
  background-color: #60dbdb;
  color: #2c3c45;
}

.featured-img--block {
  width: 85%;
  height: 60vh;
  max-width: 100em;
  min-height: 40vmin;
  margin: 3em auto 0em;
  border: 0.7em solid #fff;
}

.featured-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (min-width: 992px) {
  .blog-post.blog-link:nth-of-type(even) .blog-featured-img {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    margin-right: 0;
    margin-left: 3em;
  }
  .blog-post.blog-link:nth-of-type(even) .blog-header {
    padding-right: 1em;
    padding-left: 7em;
  }
  .blog-post.blog-link:nth-of-type(2) .blog-image {
    box-shadow: -16px 16px 0 0 #f2ca61;
  }
}

@media screen and (max-width: 991px) {
  .blog-wrap {
    position: relative;
    padding-top: 0px;
    padding-right: 45px;
    padding-left: 40px;
  }

  .blog-post.blog-link {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .blog-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .blog-header {
    position: relative;
    z-index: 1;
    margin-right: 0em;
    margin-left: 0em;
    padding: 6vw 9vw;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    background-color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.7)), to(hsla(0, 0%, 100%, 0.7))), url('../images/texturetastic_gray_2X.jpg');
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.7), hsla(0, 0%, 100%, 0.7)), url('../images/texturetastic_gray_2X.jpg');
    background-position: 0px 0px, 0px 0px;
    background-size: auto, 476px;
    box-shadow: 13px 13px 0 -5px #f2ca61;
  }
  
  .blog-featured-img {
    z-index: 2;
    min-height: 46vmin;
  }

  .blog-image {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    box-shadow: 16px 0 0 -8px #f2ca61;
    -webkit-transform: translate(0px, 0%);
    -ms-transform: translate(0px, 0%);
    transform: translate(0px, 0%);
  }

  .archive-pagination {
    position: relative;
  }

  .featured-img--block {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .blog-header {
    padding-right: 9vw;
    padding-left: 9vw;
  }

  .blog-wrap {
    margin-top: 15px;
    padding-right: 40px;
  }

  .archive-pagination {
    margin-top: 0px;
  }

  .featured-img--block {
    height: 25em;
  }
}

@media screen and (max-width: 479px) {
  .blog-title {
    font-size: 1.6rem;
  }

  .blog-post {
    -webkit-flex-basis: 95%;
    -ms-flex-preferred-size: 95%;
    flex-basis: 95%;
  }

  .blog-wrap {
    padding-right: 12px;
    padding-left: 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .excerpt {
    font-size: 1rem;
  }

  .archive-pagination {
    padding-right: 10px;
    padding-left: 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .featured-img--block {
    height: 20em;
  }

  .prev-next {
    width: 100%;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
}
